*
{
	margin: 0;
	padding: 0;
}

.webgl
{
	position: fixed;
	top: 0;
	left: 0;
	outline: none;
}

html,
body
{
	overflow: hidden;
}

#scene_gui { position: absolute; top: 2px; left: 2px }
#vector_gui { position: absolute; top: 2px; left: 200px }
#vector_operations_gui { position: absolute; top: 2px; left: 200px }

div.terminal-pane {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

#editor {
	margin-bottom: 64px;
	margin-left : 0px;
	width: 100%;
	height: 200px;
	background-color: white;
	color: green;
	position: fixed;
	bottom: 0%;
	overflow:scroll;
	font-size: 18px;
}

.runButton {
	background-color: #4CAF50;
	border: none;
	color: white;
	padding: 15px 32px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	margin: 4px 2px;
	cursor: pointer;
	position:fixed;
	bottom:0%;
}